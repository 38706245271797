<template>
  <div class="user-list">
    <div class="w-100 text-center">
      <h1>List Users</h1>
    </div>

    <div class="user-toolbar">
      <button class="rebutton" @click="registerUser">Add User</button>
    </div>
      <b-table striped hover :items="users" v-if="users" :fields="tableFields"
               class="table-data" sort-by="user_id"
      >
        <template #cell(active)="row">
          <b-checkbox switch v-model="row.item.active" @change="toggleUserEnabled(row.item)"></b-checkbox>
        </template>
        <template #cell(password_change_required)="row">
          <b-checkbox switch v-model="row.item.password_change_required" @change="togglePasswordChange(row.item)"></b-checkbox>
        </template>
        <template #cell(actions)="row">
          <b-button variant="success" size="sm" @click="editUser(row.item)" v-if="hasRole('admin', currentUser)">Edit</b-button>
<!--          <b-button variant="success" size="sm" @click="editUser(row.item)" v-if="hasRole('admin', currentUser)">Edit</b-button>-->
<!--          <b-button variant="danger" size="sm" @click="forcePasswordReset(row.item)" v-if="!row.item.password_change_required">Force Password Reset</b-button>-->
<!--          <b-button variant="warning" size="sm" @click="disableUser(row.item)" v-if="row.item.active">Disable User</b-button>-->
<!--          <b-button variant="success" size="sm" @click="enableUser(row.item)" v-if="!row.item.active">Enable User</b-button>-->
        </template>
        <template #cell(roles)="row">
          <b-dropdown title="Roles">
            <b-dropdown-header>Edit User Roles</b-dropdown-header>
            <b-dropdown-form>
              <div class="d-flex flex-row flex-nowrap" v-for="(role, idx) in us.roleNames" v-bind:key="idx">
                <input type="checkbox"
                       :disabled="role==='admin' && !isAdmin"
                       @change="toggleRole(hasRole(role, row.item), role, row.item)"
                       :id="row.item.user_id + '_' + role"
                       :checked="hasRole(role, row.item)">
                <label class="checkbox-label" :for="row.item.user_id + '_' + role">{{role}}</label>
              </div>
            </b-dropdown-form>
          </b-dropdown>
        </template>
      </b-table>
    <p>
<!--      <b-button variant="primary" @click="$router.push({name: 'AddUser'})">Add User</b-button>-->
    </p>
    <b-modal id="chooseRoleToAdd" title="Choose Role to Add" @ok="addRoleToUser">
      <b-form-group label="Role">
        <b-form-select v-model="roleToAdd" :options="us.roleNames"></b-form-select>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import UserService from "@/services/user.service";


export default {
  name: 'UserList',
  components: {},
  data() {
    return {
      us: UserService,
      users: [],
      roleToAdd: '',
      selectedUser: '',
      tableFields: [
        {key: 'user_id', label: '#'},
        {key: 'email', label: 'Email'},
        {key: 'display_name', label: 'Name'},
        {key: 'organisation', label: 'Organisation'},
        {key: 'active', label: 'Enabled'},
        {key: 'password_change_required', label: 'Password Expired'},
        {key: 'roles', label: 'Roles'},
        {key: 'actions', label: 'Actions'}
      ],
      searchQuery: {
        'active_only': false
      },
      currentUser: null
    };
  },
  async mounted() {
    if (!this.$store.state.auth.status.loggedIn) {
      this.$router.push('/auth/login')
    }
    this.currentUser = this.$store.state.auth.user
    await this.getUsers()
  },
  methods: {
    editUser(user) {
      this.$router.push('/admin/users/' + user.user_id)
    },
    async setPasswordExpiry(user, state) {
      UserService.forceResetPassword(user.user_id, state).then(
          async response => {
            console.debug(response.data)
            await this.getUsers()
            this.$bvToast.toast(`Password expiry for '${user.email}' set to ${state} .`, {
              title: 'User Account Updated',
              variant: 'success',
              toaster: 'b-toaster-top-center'
            })
          },
          error => {
            this.$bvToast.toast(`Error setting password expiry for ${user.email}.`, {
              title: 'Server Error',
              variant: 'danger',
              toaster: 'b-toaster-top-center'
            })
          }
      )
    },
    async disableUser(user) {
        UserService.disableUser(user.user_id).then(
            async response => {
              console.debug(response.data)
              await this.getUsers()
              this.$bvToast.toast(`User Account '${user.email}' was DISABLED.`, {
                title: 'User Account Updated',
                variant: 'success',
                toaster: 'b-toaster-top-center'
              })
            },
            error => {
              this.$bvToast.toast(`Error Disabling User Account ${user.email}.`, {
                title: 'Server Error',
                variant: 'danger',
                toaster: 'b-toaster-top-center'
              })
            }
        );
    },
    async removeRole(user, role_name){
      console.log(`Removing role ${role_name} from user ${user.email}`)
        UserService.removeRole(user.user_id, role_name).then(
            async response => {
              console.debug(response.data)
              await this.getUsers()
              this.$bvToast.toast(`Role '${role_name}' removed from user '${user.email}'.`, {
                title: 'User Role Removed',
                variant: 'success',
                toaster: 'b-toaster-top-center'
              })
            },
            error => {
              this.$bvToast.toast('Error Removing User Role.', {
                title: 'Server Error',
                variant: 'danger',
                toaster: 'b-toaster-top-center'
              })
            }
        )
    },
    async addRoleToUser(){
      console.log(this.selectedUser)
      console.log(this.roleToAdd)
      await this.addRole(this.selectedUser, this.roleToAdd)
    },
    async addRole(user, role_name){
      console.log(`Adding role ${role_name} from user ${user.email}`)
        UserService.addRole(user.user_id, role_name).then(
            async response => {
              console.debug(response.data)
              await this.getUsers()
              this.$bvToast.toast(`Role '${role_name}' added to user '${user.email}'.`, {
                title: 'User Roles Added',
                variant: 'success',
                toaster: 'b-toaster-top-center'
              })
            },
            error => {
              this.$bvToast.toast('Error Granting User Role.', {
                title: 'Server Error',
                variant: 'danger',
                toaster: 'b-toaster-top-center'
              })
            }
        )
    },
    async enableUser(user) {
        UserService.enableUser(user.user_id).then(
            async response => {
              console.debug(response.data)
              await this.getUsers()
              this.$bvToast.toast(`User Account '${user.email}' was ENABLED.`, {
                title: 'User Account Updated',
                variant: 'success',
                toaster: 'b-toaster-top-center'
              })
            },
            error => {
              this.$bvToast.toast(`Error Enabling User Account ${user.email}.`, {
                title: 'Server Error',
                variant: 'danger',
                toaster: 'b-toaster-top-center'
              })
            }
        );
    },
    async getUsers() {
      UserService.getUsers(this.searchQuery).then(
          response => {
            this.users = response.data;
          },
          error => {
            this.content =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
          }
      );
    },
    toggleRole (currentState, roleName, user) {
      if (!user) {
        return
      }
      if (currentState) {
        this.removeRole(user, roleName)
      } else {
        this.addRole(user, roleName)
      }
    },
    toggleUserEnabled (user) {
      if (user.active) {
        this.enableUser(user)
      } else {
        this.disableUser(user)
      }
    },
    togglePasswordChange(user) {
      this.setPasswordExpiry(user, user.password_change_required)
    },
    hasRole(roleName, user) {
      if (!user) {
        return false
      }
      if (!user.roles){
        user.roles = []
      }
      return user.roles.some(role => role.name === roleName)
    },
    registerUser() {
      this.$router.push('/admin/users/register')
    }
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth.user.is_admin
    }
  }
}

</script>

<style scoped>

.card-container{
  min-width: 1000px;
}
.user-list {
  min-height: 50vh;
}

.user-toolbar {
  display: flex;
  flex-flow: row-reverse wrap;
  justify-items: flex-end;
  padding: 5px;
}

button.btn{
  margin-right: 0.3em;
}

.checkbox-label {
  border: none;
  background: none;
  margin: 0;
}

</style>
